<template>
  <v-container fluid class="text-center">
    <v-breadcrumbs :items="path" large></v-breadcrumbs>
    <v-toolbar-title dense dark class="title text-left mt-5 mx-5">
      <v-row style="align-items: center">
        <v-col cols="12" md="3" xs="12"> Proveedores </v-col>
        <v-col cols="12" md="9" xs="12" class="text-end">
          <span
            class="caption grey--text darken-2"
            style="white-space: normal; font-family: 'Quicksand', sans-serif !important"
          >
            Los proveedores ayudan en la configuración del servicio que se está usando, es importante tener una
            segmentación de cuentas según el uso y clasificación de pago administrativo.
          </span>
        </v-col>
      </v-row>
    </v-toolbar-title>
    <v-card class="elevation-5 mt-5 mx-5">
      <v-card-title style="gap: 20px">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          class="mx-2"
          single-line
          hide-details
          maxlength="150"
          style="max-width: 400px"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          small
          :loading="loading"
          elevation="1"
          color="green"
          class="mx-2 custom-button white--text"
          @click="dialogs.new = true"
        >
          Agregar
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
        :items-per-page="10"
        item-key="id"
        class="elevation-1"
        dense
      >
        <v-progress-linear slot="progress" color="primary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon
                class="mr-2"
                size="22"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="view(item)"
                >mdi-pencil</v-icon
              ></template
            ><span>Editar</span></v-tooltip
          >
          <v-tooltip right
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon
                class="mr-2"
                size="22"
                color="error"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="remove(item.id_provider_setting)"
                >mdi-delete</v-icon
              ></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>
        <template v-slot:[`footer.page-text`]="props">
          Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogs.new" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Nuevo proveedor</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formNew" lazy-va2lidation>
              <v-row class="mt-2">
                <template v-for="(item, idx) in Object.keys(forms.new)">
                  <v-col v-if="forms.new[item]" cols="12" :key="idx">
                    <v-text-field
                      v-model="forms.new[item].value"
                      :label="forms.new[item].label"
                      :rules="rules[item]"
                      :disabled="loading"
                      required
                      dense
                      outlined
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Actualizar proveedor</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row class="mt-2">
                <template v-for="(item, idx) in Object.keys(forms.update)">
                  <v-col v-if="forms.update[item]" cols="12" :key="idx">
                    <v-text-field
                      v-if="forms.update[item].show"
                      v-model="forms.update[item].value"
                      :label="forms.update[item].label"
                      :rules="rules[item]"
                      :disabled="loading"
                      required
                      dense
                      outlined
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar el proveedor?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()"> Cancelar </v-btn>
          <v-btn text small :loading="loading" color="error" @click="remove()"> Eliminar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from "@/utils/services";

export default {
  name: "provider",
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: "",
    error: false,
    msgError: "",
    search: "",
    path: [
      {
        text: "SMS",
        disabled: false,
        href: "home",
      },
      {
        text: "Ajustes",
        disabled: false,
        href: "",
      },
      {
        text: "Proveedores",
        disabled: false,
        href: "",
      },
    ],
    headers: [
      { text: "Proveedor", align: "left", sortable: false, value: "provider" },
      { text: "Nombre", align: "left", sortable: false, value: "name" },
      { text: "Descripción", align: "left", sortable: false, value: "description" },
      { text: "Acciones", align: "left", sortable: false, value: "actions" },
    ],
    items: [],
    selected: [],
    deleted: null,
    catalogues: {},
    forms: {
      new: {
        name: { label: "Nombre", value: "", type: "string" },
        description: { label: "Descripción", value: "", type: "string" },
        accountSid: { label: "accountSid", value: "", type: "string" },
        authToken: { label: "authToken", value: "", type: "string" },
        sms: { label: "sms", value: "", type: "string" },
      },
      update: {
        id: { label: "id", value: "", type: "string", show: false },
        name: { label: "Nombre", value: "", type: "string", show: true },
        description: { label: "Descripción", value: "", type: "string", show: true },
        accountSid: { label: "accountSid", value: "", type: "string", show: true },
        authToken: { label: "authToken", value: "", type: "string", show: true },
        sms: { label: "sms", value: "", type: "string", show: true },
      },
    },
    rules: {},
    dialogs: {
      new: false,
      update: false,
      remove: false,
    },
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      this.axios
        .get(services.routes.provider)
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset();
        for (let key in this.forms.new) {
          this.forms.new[key].value = "";
        }
        this.dialogs.new = false;
      } else if (this.dialogs.update) {
        this.$refs.formUpdate.reset();
        for (let key in this.forms.update) {
          this.forms.update[key].value = "";
        }
        this.dialogs.update = false;
      } else if (this.dialogs.remove) {
        this.dialogs.remove = false;
      }
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true;
        const data = {
          provider: "TWILIO",
          name: this.forms.new.name.value,
          description: this.forms.new.description.value,
          data: {
            accountSid: this.forms.new.accountSid.value,
            authToken: this.forms.new.authToken.value,
            services: {
              sms: this.forms.new.sms.value,
            },
          },
        };
        this.axios
          .post(services.routes.provider, {
            data: data,
          })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = "Proveedor creado exitosamente";
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = "La información es incorrecta";
      }
    },
    view(data, type) {
      this.forms.update = {
        id_provider_setting: { label: "id", value: data.id_provider_setting, type: "string", show: false },
        name: { label: "Nombre", value: data.name, type: "string", show: true },
        description: { label: "Descripción", value: data.description, type: "string", show: true },
        accountSid: { label: "accountSid", value: data.data.accountSid, type: "string", show: true },
        authToken: { label: "authToken", value: data.data.authToken, type: "string", show: true },
        sms: { label: "sms", value: data.data.services.sms, type: "string", show: true },
      };
      this.dialogs.update = true;
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        const data = {
          id_provider_setting: this.forms.update.id_provider_setting.value,
          provider: "TWILIO",
          name: this.forms.update.name.value,
          description: this.forms.update.description.value,
          data: {
            accountSid: this.forms.update.accountSid.value,
            authToken: this.forms.update.authToken.value,
            services: {
              sms: this.forms.update.sms.value,
            },
          },
        };
        this.axios
          .put(services.routes.provider, {
            data: data,
          })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = "Proveedor actualizado exitosamente";
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = "La información es incorrecta";
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        this.axios
          .delete(services.routes.provider, { data: { data: { id_provider_setting: this.deleted } } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = "Proveedor eliminado exitosamente";
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-family: "Quicksand", sans-serif !important;
  font-size: 2rem !important;
  line-height: 1.2;
}
</style>
